/* eslint-disable jsx-a11y/iframe-has-title */
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CommunityAgentApplicationStatus, User } from '../../utils/types'
import UpdateIcon from '@mui/icons-material/Update'
import { FormGroup } from 'reactstrap'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { toast } from 'react-toastify'
import {
  getPendingCommunityAgentUsers,
  putApproveCommunityAgentUser,
  putDisapproveUserCommunityAgent,
} from '../../api'
import './index.css'
export default function ApproveAgentFromList() {
  const [users, setUsers] = useState<User[]>([])
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [searchType, setSearchType] = useState('name') // Default search type is name
  const [searchTerm, setSearchTerm] = useState('')
  const [saveMessage, setSaveMessage] = useState('')
  const [disApprovalReason, setDisApprovalReason] = useState('')
  const [isApproveCommunityAgentLoading, setIsCommunityAgentApproveLoading] =
    useState(false)
  const [
    isDisApproveCommunityAgentLoading,
    setIsDisApproveCommunityAgentLoading,
  ] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)
  const getPendingApprovalUsersCommunityAgent = async () => {
    setIsCommunityAgentApproveLoading(true)
    getPendingCommunityAgentUsers(searchType, searchTerm)
      .then((res) => {
        const users = res.data.users
        setUsers(users)
      })
      .catch((err) => {
        console.log('error=============', err)
      })
      .finally(() => setIsCommunityAgentApproveLoading(false))
  }

  const handleApproveCommunityAgent = (email: any) => {
    setIsCommunityAgentApproveLoading(true)
    putApproveCommunityAgentUser(email)
      .then((res) => {
        setSaveMessage(res.data.message)
        setTimeout(() => {
          setSaveMessage('')
          getPendingApprovalUsersCommunityAgent()
          setSelectedUser(null)
          toggle()
        }, 500)
        setIsCommunityAgentApproveLoading(false)
      })
      .catch((err) => {
        console.log('error=============', err)
        setIsCommunityAgentApproveLoading(false)
      })
  }
  const handleDisApproveUserCommunityAgent = async (email: string) => {
    setIsDisApproveCommunityAgentLoading(true)
    await putDisapproveUserCommunityAgent(email, disApprovalReason)
      .then((res) => {
        setSaveMessage(res.data.message)
        setTimeout(() => {
          setSaveMessage('')
          getPendingApprovalUsersCommunityAgent()
          setSelectedUser(null)
          toggle()
        }, 500)
        setIsDisApproveCommunityAgentLoading(false)
      })
      .catch((error: any) => {
        toast.error(error.response.data.message)
        setIsDisApproveCommunityAgentLoading(false)
      })
  }
  // Debounced version of getPendingApprovalUsersCommunityAgent for 500 mili seconds
  // const debouncedGetAllVerifiedUsers = debounce(getPendingApprovalUsersCommunityAgent, 500)

  useEffect(() => {
    getPendingApprovalUsersCommunityAgent()
  }, [])

  useEffect(() => {
    if (selectedUser) {
      setModal(true)
    }
  }, [selectedUser])

  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper ">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                Approve Community Agent
              </li>
            </ol>
            {isApproveCommunityAgentLoading && <h4>loading....</h4>}
            {!isApproveCommunityAgentLoading && users?.length === 0 && (
              <h4>
                No Agent Found with Status as{' '}
                {CommunityAgentApplicationStatus.Pending}
              </h4>
            )}
            <div className="card mb-3">
              <div className="card-body">
                <div className="table-responsive">
                  <FormGroup className="search-by-wrap">
                    <select
                      className="form-control"
                      value={searchType}
                      onChange={(e) => setSearchType(e.target.value)}
                    >
                      <option value="name">Search User by Name</option>
                      <option value="email">Search User by Email</option>
                    </select>
                    <SearchInput
                      onClick={getPendingApprovalUsersCommunityAgent}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </FormGroup>
                  <table
                    className="table table-bordered text-center"
                    id="dataTable"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Reason to join</th>
                        <th>Motivatation And Experinece</th>
                        <th> Approve/Disapprove</th>
                      </tr>
                    </thead>
                    {users?.map((user: User, index: number) => (
                      <tr key={index}>
                        <td>
                          <b>{index + 1}.</b>
                        </td>
                        <td>{user.firstName + ' ' + user.lastName}</td>
                        <td>{user?.email}</td>
                        <td>
                          {user.ratings.communityAgent.reasonToBecomeAgent
                            .length > 15
                            ? `${user.ratings.communityAgent.reasonToBecomeAgent.slice(0, 50)}...`
                            : user.ratings.communityAgent.reasonToBecomeAgent}
                        </td>
                        <td>
                          {user.ratings.communityAgent.experienceAndMotivation
                            .length > 15
                            ? `${user.ratings.communityAgent.experienceAndMotivation.slice(0, 50)}...`
                            : user.ratings.communityAgent
                                .experienceAndMotivation}
                        </td>

                        <td onClick={() => setSelectedUser(user)}>
                          <UpdateIcon style={{ padding: '0px' }} />
                        </td>
                      </tr>
                    ))}
                  </table>
                  <Modal
                    className="approveUserFromList-modal"
                    isOpen={modal}
                    toggle={toggle}
                    centered
                    size="lg"
                    scrollable
                    onClosed={() => {
                      setSelectedUser(null)
                    }}
                  >
                    <ModalHeader toggle={toggle}>
                      Approving Community Agent
                    </ModalHeader>
                    <ModalBody>
                      {saveMessage && (
                        <div className="save-message">{saveMessage}</div>
                      )}

                      {selectedUser && (
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="box_general padding_bottom add-land-form">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Full Name</label>
                                    <b className="form-control">
                                      {selectedUser.firstName +
                                        ' ' +
                                        selectedUser.lastName}
                                    </b>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Email </label>
                                    <b className="form-control">
                                      {selectedUser.email}
                                    </b>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label>Reason to become an Agent</label>
                                    <b className="form-control text-wrap text-break overflow-auto custom-text-wrap">
                                      {
                                        selectedUser.ratings.communityAgent
                                          .reasonToBecomeAgent
                                      }
                                    </b>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label>
                                      Experince/Motivation to become an Agent
                                    </label>
                                    <b className="form-control text-wrap text-break overflow-auto custom-text-wrap">
                                      {
                                        selectedUser.ratings.communityAgent
                                          .experienceAndMotivation
                                      }
                                    </b>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <QButton
                        color="primary"
                        round
                        onClick={() => {
                          toggle()
                          setTimeout(() => {
                            setSelectedUser(null)
                          }, 200)
                        }}
                      >
                        Close
                      </QButton>
                      <QButton
                        loading={isApproveCommunityAgentLoading}
                        color="secondary"
                        round
                        onClick={() => {
                          handleApproveCommunityAgent(selectedUser?.email)
                        }}
                      >
                        Approve
                      </QButton>{' '}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="disApprovalReason">
                            Reason for disapproval (Max 300 characters)
                          </label>
                          <textarea
                            id="disApprovalReason"
                            className="form-control"
                            onChange={(e) =>
                              setDisApprovalReason(e.target.value)
                            }
                            maxLength={300}
                          />
                        </div>
                      </div>
                      <QButton
                        loading={isDisApproveCommunityAgentLoading}
                        color="secondary"
                        round
                        onClick={() => {
                          handleDisApproveUserCommunityAgent(
                            selectedUser?.email as string,
                          )
                        }}
                      >
                        Disapprove
                      </QButton>{' '}
                    </ModalFooter>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
