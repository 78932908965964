import {
  Credentials,
  User,
  OrderCreated,
  TransferUnit,
  TransferUnitCreated,
  Transaction,
  PayoutInfo,
  SuperAdmin,
  ExternalCardCryptoFeeFormData,
  ReferenceCode,
  News,
  LandProjectObjectInteface,
  NewsCategoryInterface,
} from '../utils/types'
import http from '../services/httpService'
import { ProjectDetails, Question } from '../utils/types'
import axios from 'axios'

const postApproveProfile = () =>
  http.post<{ user: User }>('/user/approve_profile')

const postUpdateProfile = (user: FormData) =>
  http.post<{ user: User }>('/user/update_profile', user)

const postUpdateBussinesProfile = (user: FormData) =>
  http.post<{ user: User }>('/user/update_business_profile', user)

const postResetPassword = (email: string) =>
  http.post('/auth/login/forgot', { email })

const postResetPasswordChange = (password: string, token: string) =>
  http.post<void>(`/auth/login/reset/${token}`, { password })
const getVerifyUserPasswordToken = (token: string) =>
  http.get<void>(`/auth/verify_password_token/${token}`)

const postLogin = (credentials: Credentials) =>
  http.post<{
    user: User
    message: string
  }>('/auth/login', credentials)

const postUnitTransfer = (transferUnit: TransferUnit) =>
  http.post<{ transferUnitCreated: TransferUnitCreated }>(
    '/user/transfer_unit',
    transferUnit,
  )

const postUpdateOrderAmount = (orderCreated: OrderCreated) =>
  http.post<{ orderUpdatedData: OrderCreated }>(
    '/user/update_order_amount',
    orderCreated,
  )

const postLogout = () => http.post<void>('/user/logout')

const createProject = (projectDetails: ProjectDetails) =>
  http.post(`/projectdetail/create_project`, projectDetails)

const createNews = (news: FormData) =>
  http.post<{ message: string }>(`/news/create`, news)
const addImage = (data: any) =>
  http.post<{ message: any }>(`/news/add-image`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })

const getAllUserAdminQuery = (searchType: string, searchTerm: string) =>
  http.get(
    `/user/getAllUserAdminQuery?searchType=${searchType}&searchTerm=${searchTerm}`,
  )

const putUpdateUserDetails = (user: User) =>
  http.put<{ message: string; user: User }>('/user/editUserDetails', user)
const postGovernanceQuestion = (data: {
  questionsList: Question[]
  selectedAnswers: string[]
}) => http.post<{ message: string }>('/goverance', data)
const postApproveBuyStripeOrder = (data: { orderId: any }) =>
  http.post(`/order/approveBuyStripeOrder`, data)

const postApproveBuyBankOrder = (data: { orderId: any }) =>
  http.post(`/order/approveBuyBankOrder`, data)

const postDisApproveBuyOrder = (data: { orderId: any }) =>
  http.post(`/order/disApproveBuyOrder`, data)

const postApproveBuyOtherOrder = (data: { orderId: any }) =>
  http.post(`/order/approveBuyOtherOrder`, data)
const postApprovePayout = (data: { payoutId: string }) =>
  http.post<PayoutInfo[]>(`/payout/approvePayout`, data)

const putApproveUser = (email: string) =>
  http.put(`/user/userVerified/${email}`)

const putApproveCommunityAgentUser = (email: string) =>
  http.put(`/user/approveCommunityAgent/${email}`)
const putAssignOrRemoveAdminRole = (roleAndUserId: {
  userId: string
  roles: string[]
}) => http.put(`/user/assignOrRemoveAdminRole/`, roleAndUserId)

const putEnableOrDisabledUser = (userId: string) =>
  http.put(`/user/toggleEnabledOrDisabledUser/${userId}`)
const deleteUser = (userId: string) => http.delete(`/user/delete/${userId}`)
const putProjectDetails = (projectdetails: Partial<ProjectDetails>) =>
  http.put(`/projectdetail/update_project/`, projectdetails)
const putNews = (news: FormData) => http.put(`/news/update/`, news)
const deleteNews = (newsId: string) => http.delete(`/news/delete/${newsId}`)
const sentNewsTo = (newsId: string, isMember: boolean) =>
  http.put<{ message: string }>(`/news/sentTo/`, { id: newsId, isMember })

const putDisapproveUser = (id: string, disapprovalReason: string) =>
  http.put<{ message: string }>(`/user/disApproveUser/`, {
    id,
    disapprovalReason,
  })

const putDisapproveUserCommunityAgent = (
  email: string,
  disapprovalReason: string,
) =>
  http.put<{ message: string }>(`/user/disApproveUserCommunityAgent/`, {
    email,
    disapprovalReason,
  })
const editGovernanceQuestion = (
  id: string,
  data: {
    questionsList: Question[]
    selectedAnswers: string[]
  },
) => http.put<{ message: string }>(`/goverance/${id}`, data)
const deleteGovernanceQuestion = (id: string) =>
  http.delete<{ message: string }>(`/goverance/${id}`)
const updateExternalFeesApi = (
  updatedExternalFees: ExternalCardCryptoFeeFormData,
) => http.put('/externalfee', updatedExternalFees)
const updateCurrencyUnitFeesApi = (formData: {
  type: string
  buyOrderTransactionFeeInCents: number
  sellOrderTransactionFeeInUnits: number
  secondaryMarketTransactionPercentage: number
}) => http.put('/currencyUnits', formData)

const getReferenceCodebyCode = (searchByReferenceCode: string) =>
  http.get(
    `/referencecode/searchByReferenceCode?referenceCode=${searchByReferenceCode}`,
  )

const putReferencecode = (referencecode: Partial<ReferenceCode>) =>
  http.put(`/referencecode/update_code/`, referencecode)

const postReferencecode = (referencecode: Partial<ReferenceCode>) =>
  http.post(`/referencecode/create_code/`, referencecode)
const getProjectByLandNameForEdit = (searchprojectByName: string) =>
  http.get(
    `/projectdetail/searchProjectNameForEdit?projectName=${searchprojectByName}`,
  )

const getSuperAdminByNameForView = (searchType: string, searchTerm: string) =>
  http.get(
    `/user/searchSuperAdminNameForView?searchType=${searchType}&searchTerm=${searchTerm}`,
  )
const getProjectByLandNameForView = (searchProjectByName: string) =>
  http.get(
    `/projectdetail/searchProjectNameForView?projectName=${searchProjectByName}`,
  )
const getNewsByTitle = (title: string) =>
  http.get<{ news: News[] }>(`/news/searchNewsByTitle?title=${title}`)
const getUserListByNameOrEmailForUpdate = (
  searchType: string,
  searchTerm: string,
  isDownload: boolean = false,
) =>
  http.get(
    `/user/searchUserListByNameOrEmailForUpdate?searchType=${searchType}&searchTerm=${searchTerm}&isDownload=${isDownload}`,
  )

const getUserListByNameOrEmailForEnableDisable = (
  searchType: string,
  searchTerm: string,
) =>
  http.get(
    `/user/searchUserListByNameOrEmailForEnableDisable?searchType=${searchType}&searchTerm=${searchTerm}`,
  )

const getUserListByNameOrEmailForDelete = (
  searchType: string,
  searchTerm: string,
) =>
  http.get(
    `/user/searchUserListByNameOrEmailForDelete?searchType=${searchType}&searchTerm=${searchTerm}`,
  )

const getSubscriberListByEmail = (email: string, isDownload: boolean = false) =>
  http.get(
    `/subscribe/getSubscriberListByEmail?email=${email}&isDownload=${isDownload}`,
  )

const getExportUserList = () => http.get(`/user/exportUsers`)
const getUserOrderApprovedOrCompleted = (
  currentPage: number,
  pageSize: number,
  searchType: string,
  searchTerm: string,
  fromDate: string,
  toDate: string,
  isDownload: boolean = false,
) =>
  http.get(
    `/order/getuserorderapprovedorcompleted?page=${currentPage}&pageSize=${pageSize}&searchType=${searchType}&searchTerm=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}&isDownload=${isDownload}`,
  )

const getUserbuyOrderPaymentSentWithoutCardAndBank = (
  currentPage: number,
  pageSize: number,
  searchType: string,
  searchTerm: string,
  fromDate: string,
  toDate: string,
  isDownload: boolean = false,
) =>
  http.get(
    `/order/getUserbuyOrderPaymentSentWithoutCardAndBank?page=${currentPage}&pageSize=${pageSize}&searchType=${searchType}&searchTerm=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}&isDownload=${isDownload}`,
  )

const getUserbuyOrderPaymentSentWithBank = (
  currentPage: number,
  pageSize: number,
  searchType: string,
  searchTerm: string,
  fromDate: string,
  toDate: string,
  isDownload: boolean = false,
) =>
  http.get(
    `/order/getUserbuyOrderPaymentSentWithBank?page=${currentPage}&pageSize=${pageSize}&searchType=${searchType}&searchTerm=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}&isDownload=${isDownload}`,
  )

const getuserSellOrderApi = (
  currentPage: number,
  pageSize: number,
  searchType: string,
  searchTerm: string,
  fromDate: string,
  toDate: string,
  isDownload: boolean = false,
) =>
  http.get(
    `/order/getusersellorder?page=${currentPage}&pageSize=${pageSize}&searchType=${searchType}&searchTerm=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}&isDownload=${isDownload}`,
  )
const postApproveSellOrder = (data: { orderId: any }) =>
  http.post<{}>(`/order/approveSellOrder`, data)
const putAudDetails = (data: { totalAudInBank: string }) =>
  http.put<{ message: string }>('/statistics/updateAudInBank', data)
const getViewOrDownloadFileFromAwsS3ForProfile = (
  currentUserEmailViewFile: string,
  documentName: string,
  isDownload: boolean,
) =>
  http.get(
    '/user/getViewOrDownloadFileFromAwsS3/' +
      currentUserEmailViewFile +
      '/' +
      documentName +
      '/' +
      isDownload,
  )
const getViewOrDownloadFileFromAwsS3ForNews = (
  newsId: string,
  isDownload: boolean,
) =>
  http.get('/news/getViewOrDownloadFileFromAwsS3/' + newsId + '/' + isDownload)
const getStatistics = () => http.get(`/statistics`)
const getUsersTransactions = (
  currentPage: number,
  pageSize: number,
  searchType: string,
  searchTerm: string,
  fromDate: string,
  toDate: string,
) =>
  http.get(
    `/order/getAllTransactions?page=${currentPage}&pageSize=${pageSize}&searchType=${searchType}&searchTerm=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}`,
  )
const getPendingVerificationUsers = (searchType: string, searchTerm: string) =>
  http.get(
    `/user/searchUserPendingVerificationListByNameOrEmail?searchType=${searchType}&searchTerm=${searchTerm}`,
  )

const getPendingCommunityAgentUsers = (
  searchType: string,
  searchTerm: string,
  isDownload: boolean = false,
) =>
  http.get(
    `/user/searchUserCommunityAgentPendingVerificationListByNameOrEmail?searchType=${searchType}&searchTerm=${searchTerm}&isDownload=${isDownload}`,
  )
const getVerifiedCommunityAgentUsers = (
  searchType: string,
  searchTerm: string,
  isDownload: boolean = false,
) =>
  http.get(
    `/user/searchUserCommunityAgentVerifiedListByNameOrEmail?searchType=${searchType}&searchTerm=${searchTerm}&isDownload=${isDownload}`,
  )
const getVerificationUsers = (
  searchType: string,
  searchTerm: string,
  isDownload: boolean = false,
) =>
  http.get<{ users: User[] } | any>(
    `/user/searchUserVerifiedListByNameOrEmail?searchType=${searchType}&searchTerm=${searchTerm}&isDownload=${isDownload}`,
  )
const getUserAdminOrSuperAdmin = () =>
  http.get<{
    user: User | SuperAdmin
  }>('/userorsuperadmin')
const getGovernanceQuestion = (id: string) =>
  http.get<{ questionsList: Question[] }>(`/goverance/${id}`)

const getTransactions = (page: number, pageSize: number) =>
  http.get<{
    myTransactions: Transaction[]
  }>('/user/getTransactions', { params: { page, pageSize } })

const getUserNameByEmail = (email: string) =>
  http.get<{ name: string }>('/user/getUserNameByEmail', { params: { email } })

const getUserOrSuperAdmin = () => http.get(`/user/userorsuperadmin`)
const getExternalfee = () => http.get<{ data: any }>('/externalfee')
const getCurrencyUnitsApi = () => http.get<{ data: any }>('/currencyUnits')
const getGovernanceQuestions = () =>
  http.get<{ questionsList: Question[] }>(`/goverance/`)
const getAllLandProjects = () =>
  http.get<{ dataList: LandProjectObjectInteface[] }>(`/land-project`)
const getAllNewsCategory = () =>
  http.get<{ dataList: NewsCategoryInterface[] }>(`/news/news-category`)
const getProjectIdeas = () =>
  http.get<{ questionsList: Question[] }>(`/project/projectIdeas`)

const getUsersForPayouts = (
  currentPage: number,
  pageSize: number,
  searchType: string,
  searchTerm: string,
  fromDate: string,
  toDate: string,
  isDownload: boolean = false,
) =>
  http.get<PayoutInfo[] | any>(
    `/payout/getUsersForPayouts?page=${currentPage}&pageSize=${pageSize}&searchType=${searchType}&searchTerm=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}&isDownload=${isDownload}`,
  )
const getAllCommunityIdea = () => http.get(`/projectIdeas`)
const revalidateNextJsPages = (url: string) =>
  axios.get(url, {
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  })

export {
  getAllLandProjects,
  revalidateNextJsPages,
  getAllNewsCategory,
  getAllCommunityIdea,
  getProjectIdeas,
  getUserOrSuperAdmin,
  postLogin,
  postLogout,
  postResetPassword,
  getUserAdminOrSuperAdmin,
  getTransactions,
  getUserNameByEmail,
  postUpdateOrderAmount,
  postUnitTransfer,
  postUpdateProfile,
  postApproveProfile,
  postUpdateBussinesProfile,
  addImage,
  createProject,
  getStatistics,
  getUsersTransactions,
  getPendingVerificationUsers,
  putApproveUser,
  getViewOrDownloadFileFromAwsS3ForProfile,
  getVerifyUserPasswordToken,
  putAssignOrRemoveAdminRole,
  getAllUserAdminQuery,
  putEnableOrDisabledUser,
  putProjectDetails,
  getProjectByLandNameForEdit,
  getProjectByLandNameForView,
  putUpdateUserDetails,
  getUserListByNameOrEmailForUpdate,
  getUserListByNameOrEmailForEnableDisable,
  postGovernanceQuestion,
  getGovernanceQuestion,
  editGovernanceQuestion,
  getGovernanceQuestions,
  deleteGovernanceQuestion,
  getUsersForPayouts,
  postApproveBuyStripeOrder,
  postApproveBuyBankOrder,
  postApproveBuyOtherOrder,
  postApprovePayout,
  postResetPasswordChange,
  getExternalfee,
  getCurrencyUnitsApi,
  updateExternalFeesApi,
  updateCurrencyUnitFeesApi,
  getUserOrderApprovedOrCompleted,
  getUserbuyOrderPaymentSentWithBank,
  getUserbuyOrderPaymentSentWithoutCardAndBank,
  getuserSellOrderApi,
  postApproveSellOrder,
  getVerificationUsers,
  putDisapproveUser,
  putAudDetails,
  getReferenceCodebyCode,
  putReferencecode,
  postReferencecode,
  getSuperAdminByNameForView,
  postDisApproveBuyOrder,
  getNewsByTitle,
  createNews,
  getViewOrDownloadFileFromAwsS3ForNews,
  putNews,
  deleteNews,
  sentNewsTo,
  getUserListByNameOrEmailForDelete,
  deleteUser,
  getExportUserList,
  getSubscriberListByEmail,
  getPendingCommunityAgentUsers,
  getVerifiedCommunityAgentUsers,
  putApproveCommunityAgentUser,
  putDisapproveUserCommunityAgent,
}
