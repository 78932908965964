import {
  EnteredBy,
  News,
  Transaction,
  User_Basic_Info,
  VoteTopicObjectInteface,
} from './types'
import * as Yup from 'yup'

export const USER_URI = process.env.REACT_APP_USER_URI
export const formatUnixTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000) // Convert Unix timestamp to milliseconds
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }
  return date.toLocaleString('en-US', options)
}
export const ONE_EURO_TO_CENTS = 100
export const ONE_AUD_TO_CENTS = 100
export const sortByTransactionsDateAndValue = (
  property: string,
  direction: string,
  data: Transaction[],
) => {
  const sorted = [...data]

  const multiplier = direction === 'asc' ? 1 : -1
  sorted.sort((a, b) => {
    const aValue = getProperty(a, property)
    const bValue = getProperty(b, property)

    if (aValue < bValue) return -1 * multiplier
    if (aValue > bValue) return 1 * multiplier
    return 0
  })
  return sorted
}
const getProperty = (obj: any, path: string) => {
  const parts = path.split('.')
  let value = obj
  for (const part of parts) {
    value = value[part]
  }
  return value
}

export const formatDate = (dateString: string) => {
  const options = {
    year: 'numeric' as const, // Ensure 'year' property is of type 'numeric'
    month: 'short' as const,
    day: 'numeric' as const,
    hour: 'numeric' as const,
    minute: 'numeric' as const,
    hour12: true,
  }

  return new Date(dateString).toLocaleDateString('en-US', options)
}

export const getDate = (dateString: string) => {
  const options = {
    year: 'numeric' as const,
    month: 'short' as const,
    day: 'numeric' as const,
  }

  return new Date(dateString).toLocaleDateString('en-US', options)
}

export const formatFormDate = (dateString: Date | string) => {
  const date = new Date(dateString)

  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Note: Months are zero-based, so we add 1
  const year = date.getFullYear()

  const formattedDate = `${year}-${month}-${day}`

  return formattedDate
}

export const debounce = (func: Function, delay: number) => {
  let timer: NodeJS.Timeout // Adjust the type according to your environment

  return function (this: any, ...args: any[]) {
    // Explicitly specify the type of 'this'
    clearTimeout(timer)
    timer = setTimeout(() => func.apply(this, args), delay)
  }
}

export const validateAdminVotetopicData = (data: VoteTopicObjectInteface) => {
  let validData = true

  Object.keys(data).forEach((k: string) => {
    if (k === 'voteQuestions') {
      data.voteQuestions.forEach((q) => {
        if (q.questionTitle === '') {
          validData = false
        }
        Object.keys(q.answerOptions).forEach((k) => {
          if (q.answerOptions[k as 'A' | 'B' | 'C' | 'D'] === '') {
            validData = false
          }
        })
      })
    } else {
      if (data[k as keyof VoteTopicObjectInteface] === '') {
        validData = false
      }
    }
  })

  console.log('validData:', validData)
  return validData
}
export const validateProjectData = (data: any) => {
  let validData = true

  console.log('validData:', validData)
  return validData
}

// import moment from 'moment-timezone';

// // Assuming startDate and endDate are selected by the user
// const startDateEET = moment(startDate).tz('Europe/Istanbul').format();
// const endDateEET = moment(endDate).tz('Europe/Istanbul').format();

export const getReferenceCodeSchema = () => {
  return Yup.object().shape({
    code: Yup.string().required('Code is required'),
    isPercent: Yup.boolean().required('Is Percent is required'),
    amount: Yup.number()
      .required('Amount is required')
      .min(0, 'Amount must be at least 0')
      .when('isPercent', {
        is: true,
        then: Yup.number().max(100, 'Amount cannot be more than 100'),
      }),
    isActive: Yup.boolean().required('Is Active is required'),
  })
}

export const createFormDataForAddingNews = (news: Partial<News>) => {
  const formData = new FormData()
  const {
    slug,
    metaDescription,
    title,
    metaTitle,
    article,
    img,
    id,
    status,
    newsCategoryId,
  } = news
  console.log('img:', img)
  if (id) {
    formData.append('id', id)
  }
  if (slug) {
    formData.append('slug', slug)
  }
  if (status) {
    formData.append('status', status)
  }
  if (metaDescription) {
    formData.append('metaDescription', metaDescription)
  }
  if (title) {
    formData.append('title', title)
  }
  if (metaTitle) {
    formData.append('metaTitle', metaTitle)
  }
  if (article) {
    formData.append('article', article)
  }
  if (newsCategoryId) {
    formData.append('newsCategoryId', newsCategoryId)
  }

  // @ts-ignore
  if (typeof img !== 'string' && img.length !== 0) {
    formData.append(
      'newsImage',
      // @ts-ignore
      img[0],
    )
  }
  return formData
}

export const getEnteredByName = (enteredBy: EnteredBy): string => {
  const { byUserAdmin, bySuperAdmin } = enteredBy

  // Check byUserAdmin first
  if (byUserAdmin && byUserAdmin.email) {
    const fullName =
      `${byUserAdmin.firstName} ${byUserAdmin.lastName || ''}`.trim()
    return fullName
  }

  // Check bySuperAdmin if byUserAdmin is not available
  if (bySuperAdmin && bySuperAdmin.email) {
    const fullName =
      `${bySuperAdmin.firstName} ${bySuperAdmin.lastName || ''}`.trim()
    return fullName
  }

  return '' // Return an empty string if neither has firstName
}

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
