/* eslint-disable no-lone-blocks */
import { useState, useEffect } from 'react'
//@ts-ignore
import { StickyTable, Row, Cell } from 'react-sticky-table';
import { Link } from 'react-router-dom'
import { ONE_AUD_TO_CENTS, getDate, getEnteredByName } from '../../utils/utilities'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { BuyCurrencyEnum, Transaction } from '../../utils/types'
import {
  getUserbuyOrderPaymentSentWithBank,
  postApproveBuyBankOrder,
  postDisApproveBuyOrder,
} from '../../api'
import {
  HasToolTipLabel,
  SortableTableHeader,
  TableTopFilters,
  TableTopFiltersNewUI,
  TableTopFiltersNewUIForBankPurchase,
  TRInfo,
} from '../../component/misc/smallComponents'
import { toast } from 'react-toastify'
import { SvgIcons } from '../../component/misc/SvgIcons';
import { camelCaseToSpaced } from '../../utils/common';

const Userpurchse = () => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)
  const [state, setState] = useState<any>({
    sortDirection: 'desc',
    isExportDownloadLoading: false,
    dataList: [],
    filteredData: [],
    currentPage: 1,
    pageSize: 50,
    searchTerm: '',
    searchType: 'name',
    selectedCurrency: '',
    numberOfUnits: '',
    paymentMethod: '',
    fromDate: '',
    toDate: '',
    sortBy: '',
  })

  const [orderId, setOrderId] = useState<any>('')

  const getData = async () => {
    const { currentPage, pageSize, searchType, searchTerm, fromDate, toDate } =
      state
    getUserbuyOrderPaymentSentWithBank(
      currentPage,
      pageSize,
      searchType,
      searchTerm,
      fromDate,
      toDate,
    )
      .then((res) => {
        console.log('res.data', res.data)
        setState((prevState: any) => ({
          ...prevState,
          filteredData: res.data,
          dataList: res.data,
        }))
      })
      .catch((error) => {
        console.log(error)
        toast.error(error.response.data.message)
      })
  }

  const handleApprove = () => {
    const data = {
      orderId: orderId,
    }

    postApproveBuyBankOrder(data)
      .then((res: any) => {
        getData()
        toast.success(res.data.message)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
      })
  }

  const handleDisApprove = () => {
    const data = {
      orderId: orderId,
    }

    postDisApproveBuyOrder(data)
      .then((res: any) => {
        getData()
        toast.success(res.data.message)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
      })
  }
  useEffect(() => {
    getData()
  }, [state.currentPage, state.fromDate, state.toDate])
  useEffect(() => {
    filterAndSortOrders()
  }, [
    // state.searchTerm,
    // state.searchType,
    state.selectedCurrency,
    state.numberOfUnits,
    state.paymentMethod,
    state.startDate,
    state.endDate,
    state.sortBy,
    state.sortDirection,
    state.dataList.length,
  ])

  const filterAndSortOrders = () => {
    let filtered = state.dataList

    if (state.selectedCurrency) {
      filtered = filtered.filter(
        (order: any) =>
          order.currencyUnit.selectedCurrency === state.selectedCurrency,
      )
    }
    if (state.sortBy) {
      filtered = filtered.sort((a: any, b: any) => {
        let comparison = 0

        if (state.sortBy === 'units') {
          const unitsA = a.currencyUnit?.numberOfUnits || 0
          const unitsB = b.currencyUnit?.numberOfUnits || 0
          comparison = unitsB - unitsA
        } else if (state.sortBy === 'amount') {
          const amountA = a.paymentDetails?.paymentAmount || 0
          const amountB = b.paymentDetails?.paymentAmount || 0
          comparison = amountB - amountA
        }

        return state.sortDirection === 'asc' ? -comparison : comparison
      })
    }

    setState((prevState: any) => ({ ...prevState, filteredData: filtered }))
  }
  const handleExportOrders = async () => {
    setState((prevState: any) => ({
      ...prevState,
      isExportDownloadLoading: true,
    }))
    try {
      const {
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
      } = state
      const response = await getUserbuyOrderPaymentSentWithBank(
        currentPage,
        pageSize,
        searchType,
        searchTerm,
        fromDate,
        toDate,
        true,
      )

      const blob = new Blob([response.data], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'orders.csv') // Set the file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up

      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to download CSV')
      setState((prevState: any) => ({
        ...prevState,
        isExportDownloadLoading: false,
      }))
    }
  }

  const resetAllFiltersAndSort = () => {
    setState({
      sortDirection: 'desc',
      dataList: [],
      filteredData: [],
      searchTerm: '',
      searchType: 'name',
      selectedCurrency: '',
      numberOfUnits: '',
      paymentMethod: '',
      startDate: null,
      endDate: null,
      sortBy: '',
    })
    getData()
  }

  const handleSortTableHead = (property: string) => {
    setState((prevState: any) => {
      const newSortDirection =
        property === prevState.sortBy
          ? prevState.sortDirection === 'asc'
            ? 'desc'
            : 'asc'
          : prevState.sortDirection
      return { ...prevState, sortBy: property, sortDirection: newSortDirection }
    })
  }
  return (
    <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
      <div className="content-wrapper card-ui-pages-content-wrapper">
        <div className="container-fluid">
          <ol
            className="breadcrumb"
            style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
          >
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Bank Purchase Order</li>
          </ol>

          <div className="card mb-3">
            <div className="card-body">
              <TableTopFiltersNewUIForBankPurchase
                state={state}
                setState={setState}
                getData={getData}
                handleExportOrders={handleExportOrders}
                
                
              />
              <div className="tr-card-list">
                {state?.filteredData &&
                  state.filteredData.map((item: any, index: number) => (
                    <div className="tr-card">
                      <div className="info-icon-wrap">

                        {/* <div className="ic-label-wrap">
                          <div className="ic-label withbg">
                            {SvgIcons.MoneyCash}
                            <span>{camelCaseToSpaced(item.orderStatus)}</span>
                          </div>
                        </div> */}
                        <div className="ic-label-wrap">
                          <div className="ic-label withbg">
                            {SvgIcons.CheckCircle}
                            <span>{item.currencyUnit?.selectedCurrency} ({item.currencyUnit.numberOfUnits}) - {" "}
                              {/* <i className="fa fa-euro mr-6"></i>{' '} */}
                              {item.paymentDetails?.paymentAmount / ONE_AUD_TO_CENTS}{' '} AUD
                            </span>
                          </div>
                        </div>
                        {/* <div className="ic-label-wrap">
                          <div className={item.orderType !== "BUY" ? "ic-label up" : "ic-label"}>
                            {SvgIcons.ArrowDown}
                            <span>{item.orderType}</span>
                          </div>
                        </div> */}
                      </div>
                      <div className="info-text">
                        <div className="info-text-col-wrap">

                          <div className="info-col">
                            <div className="tr-info-wrap">
                              <TRInfo
                                value={item.user?.firstName +
                                  ' ' +
                                  item.user?.lastName}
                                label={"Name"}
                              />
                            </div>
                            <div className="tr-info-wrap">
                              <TRInfo
                                value={getDate(item.orderCreatedDate?.toLocaleString())}
                                label={"Created Date"}
                              />
                            </div>

                          </div>
                          <div className="info-col">
                            <div className="tr-info-wrap">
                              <TRInfo
                                value={item.user?.email}
                                label={"Email"}
                              />
                            </div>





                          </div>
                          <div className="info-col">



                            <div className="tr-info-wrap">
                              <TRInfo
                                value={item.buy?.paymentId ? item.buy.paymentId : "--"}
                                label={"Payment Reference"}
                              />
                            </div>
                          </div>
                          <div className="info-col">
                            <div
                              className="text-right pt-4 pr-4"
                              onClick={() => setOrderId(item.orderId)}
                            >
                              <QButton size="sm" onClick={toggle}>
                                Action
                              </QButton>
                            </div>


                          </div>
                        </div>

                        {item.buy?.comments && <p className='comments'>Note(Optional) : {item.buy?.comments}</p>}

                      </div>


                    </div>
                  ),
                  )}



              </div>
              <Modal
                isOpen={modal}
                toggle={toggle}
                className="modal-dialog-centered"
              >
                <ModalHeader toggle={toggle}>
                  Approve/Dis-Approve User Bank Purchase Order
                </ModalHeader>
                <ModalBody>
                  <div className="d-flex justify-content-between">
                    <Button
                      color="success"
                      onClick={() => {
                        handleApprove()
                        toggle()
                      }}
                    >
                      Approve
                    </Button>

                    <Button
                      color="primary"
                      onClick={() => {
                        handleDisApprove()
                        toggle()
                      }}
                    >
                      Dis-Approve
                    </Button>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggle}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </body>
  )
}

export default Userpurchse
