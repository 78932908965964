import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import {
  BuyCurrencyEnum,
  OrderType,
  PaymentMethodEnum,
  Transaction,
} from '../../utils/types'
//@ts-ignore
import { StickyTable, Row, Cell } from 'react-sticky-table';

import {
  ONE_AUD_TO_CENTS,
  formatDate,
  getEnteredByName,
  sortByTransactionsDateAndValue,
} from '../../utils/utilities'
import { OrderStatus } from '../../utils/types'
import './index.scss'
import { getUsersTransactions } from '../../api'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { FormGroup, Label } from 'reactstrap'
import SingleDatePickerComponent from '../../component/SingleDatePickerComponent'
import moment from 'moment'
import { toast } from 'react-toastify'
import { HasToolTipLabel, SelectComponent, SelectComponentValue, TRInfo } from '../../component/misc/smallComponents'
import { SvgIcons } from '../../component/misc/SvgIcons';
import { camelCaseToSpaced } from '../../utils/common';
export default function AdminViewTransaction() {
  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchType, setSearchType] = useState('name') // Default search type is name

  const [filteredTransactions, setFilteredTransactions] = useState<
    Transaction[]
  >([])

  const [sortBy, setSortBy] = useState<string>('lastUpdatedAt')
  const [filterPaymentMethod, setFilterPaymentMethod] = useState<string | null>(
    null,
  )
  const [filterByStatus, setFilterByStatus] = useState<string | null>(null)
  const [filterCurrencyType, setFilterCurrencyType] = useState<string | null>(
    null,
  )
  const [filterTransactionType, setFilterTransactionType] =
    useState<OrderType | null>(null)
  const [fromDate, setFromDate] = useState<string>('') // Initialize with an empty string
  const [toDate, setToDate] = useState<string>('') // Initialize with an empty string
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')

  useEffect(() => {
    getAllTransation()
  }, [currentPage, fromDate, toDate])

  useEffect(() => {
    // Apply filters based on payment method,currency type,transaction type and transaction status
    let filteredTransactions = transactions

    if (!filteredTransactions) {
      return
    }
    if (filterPaymentMethod) {
      filteredTransactions = filteredTransactions.filter(
        (transaction) =>
          transaction.paymentDetails?.paymentMethod === filterPaymentMethod,
      )
    }

    if (filterByStatus) {
      filteredTransactions = filteredTransactions.filter((transaction) => {
        if (filterByStatus === OrderStatus.Completed) {
          return (
            transaction.orderStatus === OrderStatus.Completed ||
            transaction.orderStatus ===
            OrderStatus.AUDReadyToSendBetweenAccounts
          )
        } else {
          return transaction.orderStatus === filterByStatus
        }
      })
    }
    if (filterCurrencyType) {
      filteredTransactions = filteredTransactions.filter(
        (transaction) =>
          transaction.currencyUnit.selectedCurrency === filterCurrencyType,
      )
    }

    if (filterTransactionType) {
      filteredTransactions = filteredTransactions.filter(
        (transaction) => transaction.orderType === filterTransactionType,
      )
    }
    // if (fromDate && toDate) {
    //   // Filter transactions within the selected date range
    //   filteredTransactions = filteredTransactions.filter((transaction) => {
    //     const transactionDate = new Date(transaction.lastUpdatedAt)
    //     const fromDateObj = new Date(fromDate)
    //     const toDateObj = new Date(toDate)
    //     return transactionDate >= fromDateObj && transactionDate <= toDateObj
    //   })
    // }
    filteredTransactions = sortByTransactionsDateAndValue(
      sortBy,
      sortDirection,
      filteredTransactions,
    )
    setFilteredTransactions(filteredTransactions)
  }, [
    transactions,
    filterPaymentMethod,
    filterByStatus,
    filterTransactionType,
    filterCurrencyType,
  ])

  const getAllTransation = async () => {
    await getUsersTransactions(
      currentPage,
      pageSize,
      searchType,
      searchTerm,
      fromDate,
      toDate,
    )
      .then((response) => {
        setTransactions(response.data)
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: 'bottom-center',
        })
        console.error('Error fetching combined data:', error)
      })
  }

  const handleSort = (
    property: string,
    curSortDirection: 'asc' | 'desc' = sortDirection,
  ) => {
    if (!filteredTransactions) {
      return
    }
    let sorted = [...filteredTransactions]

    sorted = sortByTransactionsDateAndValue(property, curSortDirection, sorted)
    setFilteredTransactions(sorted)
    setSortBy(property)

    setSortDirection(curSortDirection)
  }

  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper card-ui-pages-content-wrapper">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                View Transaction History
              </li>
            </ol>
            <div className="card mb-3">
              <div className="card-header">
                <div className="tr-filter-wrapper">
                  <div className="tr-filter-row">
                    <div className="tr-col one">
                      <SelectComponent
                        placeholder="Type"
                        onChange={(value) =>
                          setFilterTransactionType(
                            value as OrderType,
                          )
                        }
                        options={[
                          { value: '', label: 'ALL' },
                          ...Object.values(OrderType).map((txType) => ({
                            value: txType,
                            label: txType,
                          }
                          ))

                        ]} />
                    </div>
                    <div className="tr-col one">
                      <SelectComponent
                        placeholder="Currency"
                        onChange={(value) =>
                          setFilterCurrencyType(value)
                        }
                        options={[
                          { value: '', label: 'ALL' },
                          ...Object.values(BuyCurrencyEnum)
                            .filter(
                              (currency) =>
                                currency !== BuyCurrencyEnum.initial,
                            )
                            .map((currency) => ({
                              value: currency,
                              label: currency,
                            }))
                        ]} />
                    </div>
                    <div className="tr-col one">
                      <SelectComponent
                        placeholder="Method"
                        onChange={(value) =>
                          setFilterPaymentMethod(value)
                        }
                        options={[
                          { value: '', label: 'ALL' },
                          ...Object.values(PaymentMethodEnum)
                            .filter(
                              (paymentMethod) =>
                                paymentMethod !== PaymentMethodEnum.None,
                            )
                            .map((method) => ({
                              value: method,
                              label: method,
                            }))
                        ]} />
                    </div>
                    <div className="tr-col one">
                      <SelectComponent
                        placeholder="Status"
                        onChange={(value) => setFilterByStatus(value)}
                        options={[
                          { value: '', label: 'ALL' },
                          ...Object.values(OrderStatus).map((status) => ({
                            value: status,
                            label: camelCaseToSpaced(status),
                          }))
                        ]} />
                    </div>


                    <div className="tr-col two">
                      <div className="row">
                        <div className="col">
                          <SelectComponentValue
                            placeholder="Search By"
                            value={searchType}
                            onChange={(value) => setSearchType(value as string)}
                            options={[
                              { value: 'name', label: 'Search By - Name' },
                              { value: 'email', label: 'Search By - Email' },
                            ]} />
                        </div>
                        <div className="col">

                          <SearchInput
                            onClick={getAllTransation}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </div>

                      </div>

                    </div>



                  </div>
                  <div className="tr-filter-row">
                    <div className="tr-col two">
                      <div className="d-flex">
                        <SingleDatePickerComponent
                          setDate={(d) =>
                            setFromDate(d.format('YYYY-MM-DD'))
                          }
                          date={fromDate ? moment(fromDate) : null}
                          id={'fromDate'}
                          placeholder="Start Date"
                          isBefore={false}
                        />
                        <SingleDatePickerComponent
                          setDate={(d) => setToDate(d.format('YYYY-MM-DD'))}
                          date={toDate ? moment(toDate) : null}
                          id={'toDate'}
                          isBefore={false}
                          placeholder="End Date"
                        />
                      </div>

                    </div>




                    <div className="tr-col one">
                      <div className="d-flex align-items-center justify-content-end  ml-auto">
                        <button
                          className="btn btn-primary pl-3 pr-3"
                          disabled={currentPage === 1}
                          onClick={() => setCurrentPage(currentPage - 1)}
                        >
                          Prev
                        </button>
                        <p className="current-page-number ml-2 mr-2 mb-0">
                          <strong>Page {currentPage}</strong>
                        </p>
                        <button
                          className="btn btn-primary pl-3 pr-3"
                          disabled={
                            filteredTransactions
                              ? filteredTransactions.length === 0
                              : true
                          }
                          onClick={() => setCurrentPage(currentPage + 1)}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="tr-card-list">
                  {filteredTransactions.map(
                    (transaction) =>
                      transaction && ( // Check if transaction is not null
                        <div className="tr-card">
                          <div className="info-icon-wrap">

                            <div className="ic-label-wrap">
                              <div className="ic-label withbg">
                                {SvgIcons.MoneyCash}
                                <span>{camelCaseToSpaced(transaction.orderStatus)}</span>
                              </div>
                            </div>
                            <div className="ic-label-wrap">
                              <div className="ic-label withbg">
                                {SvgIcons.CheckCircle}
                                <span>{transaction.currencyUnit.selectedCurrency} ({transaction.currencyUnit.numberOfUnits}) - <i className="fa fa-euro mr-6"></i>{' '}
                                  {transaction.paymentDetails.paymentAmount /
                                    ONE_AUD_TO_CENTS} </span>
                              </div>
                            </div>
                            <div className="ic-label-wrap">
                              <div className={transaction.orderType !== "BUY" ? "ic-label up" : "ic-label"}>
                                {SvgIcons.ArrowDown}
                                <span>{transaction.orderType}</span>
                              </div>
                            </div>
                          </div>
                          <div className="info-text">
                            <div className="info-text-col-wrap">

                              <div className="info-col">
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={transaction.user.firstName +
                                      ' ' +
                                      transaction.user.lastName}
                                    label={"Name"}
                                  />
                                </div>
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={transaction &&
                                      formatDate(
                                        transaction.lastUpdatedAt?.toString(),
                                      )}
                                    label={"Date"}
                                  />
                                </div>

                              </div>
                              <div className="info-col">
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={transaction.user.email}
                                    label={"Sent Email"}
                                  />
                                </div>
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={transaction.transfer?.recipientEmail ?
                                      transaction.transfer.recipientEmail : "--"
                                    }
                                    label={"Received Email"}
                                  />
                                </div>

                              </div>
                              <div className="info-col">
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={transaction.paymentDetails?.paymentMethod ? transaction.paymentDetails?.paymentMethod : "--"}
                                    label={"Payment Method"}
                                  />
                                </div>
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={getEnteredByName(transaction.approvedBy) ? getEnteredByName(transaction.approvedBy) : "--"}
                                    label={"Approved by"}
                                  />
                                </div>
                              </div>
                              <div className="info-col">
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={transaction.orderId}
                                    label={"Quantum2 Order Id"}
                                  />
                                </div>
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={transaction.buy?.paymentId ? transaction.buy.paymentId : "--"}
                                    label={"Stripe Payment Id/Bank Refrence"}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <p className='comments'>Note(Optional) : Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti, nostrum?</p> */}
                            {transaction.buy?.comments && <p className='comments'>Note(Optional) : {transaction.buy?.comments}</p>}

                          </div>


                        </div>
                      ),
                  )}
                </div>


              </div>



            </div>
          </div>
        </div>
      </body>
    </>
  )
}
