import React, { useEffect, useState } from 'react'
import { ONE_AUD_TO_CENTS } from '../../utils/utilities'
import { getStatistics } from '../../api'
import { Statistics } from '../../utils/types'
export default function Addmin() {
  const [statistics, setStatistics] = useState<Statistics>()
  const [isLoading, setLoading] = useState(false)

  const getStaticsData = async () => {
    setLoading(true)
    getStatistics()
      .then((res) => {
        setLoading(false)
        setStatistics(res.data)
      })
      .catch((error: any) => {
        console.log('error', error)
        setLoading(false)
      })
  }
  useEffect(() => {
    getStaticsData()
  }, [])
  return (
    <>
      <body className="fixed-nav sticky-footer  dash-img-bg" id="page-top">
        <div className="content-wrapper">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '1rem', paddingBottom: '0.6rem' }}
            >
              <li
                className="breadcrumb-item active"
                style={{ color: '#F0151F' }}
              >
                Admin Dashboard
              </li>
            </ol>
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <div className="user-dash-tabl py-2 admin-dashboard-table-1">
                <div className="table-responsive">
                  <table
                    className="table table-borderless table-striped "
                    width="100%"
                    cellSpacing="0"
                  >
                    {statistics && (
                      <tbody>
                        <tr>
                          <th>Total Approved Users Count</th>
                          <td>
                            <span className="q-badge">
                              {statistics.approveUserCount}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Total Approved Community Agent Count</th>
                          <td>
                            <span className="q-badge">
                              {statistics.approveUserCommunityAgentCount}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Total Project Count</th>
                          <td>
                            <span className="q-badge">
                              {statistics.projectCount}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Total AUD in Bank</th>
                          <td>
                            <span className="q-badge">
                              {parseFloat(
                                statistics.totalAudInBank.toString(),
                              ).toFixed(2)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Total eAUD Unit Purchase</th>
                          <td>
                            <span className="q-badge">
                              {parseFloat(
                                statistics.totaleAUDUnitPurchase.toString(),
                              ).toFixed(2)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Total eAUD Unit Pending Sell</th>
                          <td>
                            <span className="q-badge">
                              {parseFloat(
                                statistics.totalEaudUnitPendingSell.toString(),
                              ).toFixed(2)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Total Land Unit Purchase</th>
                          <td>
                            <span className="q-badge">
                              {statistics.totalLandUnitPurchase}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Total Land Unit Pending Purchase</th>
                          <td>
                            <span className="q-badge">
                              {statistics.totalLandUnitPendingPurchase}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Total Land Unit Pending Sell</th>
                          <td>
                            <span className="q-badge">
                              {statistics.totalLandUnitPendingSell}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Total Project Value of Land</th>
                          <td>
                            <span className="q-badge">
                              {statistics.totalProjectLandValueInCents /
                                ONE_AUD_TO_CENTS}{' '}
                              AUD
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Recent Transactions</th>
                          <td>
                            <span className="q-badge">
                              {statistics.recentTransactionCount}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>

        <a className="scroll-to-top" href="#page-top">
          <i className="fa fa-angle-up"></i>
        </a>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Ready to Leave?
                </h5>
                <button
                  className="close"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                Select "Logout" below if you are ready to end your current
                session.
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <a className="btn btn-primary" href="login.html">
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
