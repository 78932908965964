export type UserRegistration = {
  firstName: string
  lastName: string
  email: string
  password: string
}

export type ResetPasswordRequestFormValues = {
  email: string
}
export type ResetPasswordFormValues = {
  password: string
}

export type User_Analytics = {
  name: string
  owned: number
  pendingBuy: number
  pendingSell: number
  total_value_bought: number
  total_value_today: number
  profit: number
  profit_percentage: number
}

export type Prices = {
  landPricePerDay: number | null
  eAudPricePerDay: number | null
  buyOrderLandUnitTransactionFeeInCents: number | null
  buyOrderEaudUnitTransactionFeeInCents: number | null
  sellOrderLandUnitTransactionFeeInUnits: number | null
  sellOrderEaudUnitTransactionFeeInUnits: number | null
}

export type Credentials = {
  email: string
  password: string
}

export enum BuyCurrencyEnum {
  initial = '',
  LAND = 'LAND',
  eAUD = 'eAUD',
}

export enum OrderType {
  BUY = 'BUY',
  SELL = 'SELL',
  TRANSFER = 'TRANSFER',
}
export enum OrderStatus {
  Initiated = 'Initiated', // Order is initiated
  InProcess = 'InProcess', // Order is pending for processing
  Failed = 'Failed', // Order has failed (payment transaction fails due to insufficient funds or any other error )
  Cancelled = 'Cancelled', // Order is cancelled by user or system(If invalid data)
  PaymentSent = 'PaymentSent', //Order payment is done by user and sent for admin to check payment recived in bank or not
  Approved = 'Approved', // Order is approved by admin or system, now it will get credited
  Completed = 'Completed', // Order is successfully completed
  PartialComplete = 'PartialComplete', //Order is partially processed when sell order happened
  AllUnitSold = 'AllUnitSold', //Order is AllUnitSold for processing and awaiting for funds approval
  FundsWaitingApproval = 'FundsWaitingApproval', // Change ReadyToSell status to Funds waiting Approval
  AUDReadyToSendBetweenAccounts = 'AUDReadyToSendBetweenAccounts', //sending AUD after user complete buy land units using eAUD units
}

export enum PaymentMethodEnum {
  None = 'None',
  Card = 'card',
  Crypto = 'crypto',
  Bank = 'bank',
  eAUD = 'eAUD',
}

export type PaymentMethodFees = {
  fixedPercentageFees: number
  fixedCentsFees: number
}
export type PaymentDetails = {
  paymentMethod: PaymentMethodEnum // Payment method used for the transaction
  paymentAmount: number // Amount paid for the transaction
  currency: string // Currency used for the payment
  subTotal: number //amount for purchasing currency
  transactionFee: number //Transaction fee for total transaction
  paymentMethodFees: PaymentMethodFees //Payment method external fee
}

export interface PaymentDetailsWithReference extends PaymentDetails {
  paymentReference: string // Reference or code associated with the payment
}

export interface BuyCurrencyData extends OrderCreate {
  purchaseDate: Date // Date and time of the purchase
  paymentDetails: PaymentDetailsWithReference //Payment details of the transaction With refernce
  confirmationCode: string // Confirmation code for the purchase
  userId: string // Identifier of the user making the purchase
  orderId: string // Identifier of the user order for making the purchase
}

export interface OrderCreated extends OrderCreate {
  orderCreatedDate: Date // Date and time of the created order
  orderId: string // Identifier of the order,
  buy?: {
    clientSecret: string
    comments: string
    paymentId: string //PaymentId
  }
  sell?: {}
  transfer?: {
    recipientEmail: string // receipient email address
    recipientName: string
  }
}

export interface CurrencyUnit {
  numberOfUnits: number // Number of units to be purchased
  selectedCurrency: BuyCurrencyEnum // Currency type to be purchased (eAUD or Land)
  pricePerUnit: number //unit price for 1 currency in AUD
  buyOrderTransactionFeeInCents?: number //Transaction fee for selected currecny only (1 $)
  sellOrderTransactionFeeInUnits?: number // Transaction fee for selling units(but it is in unit instead of AUD)
}
export interface OrderCreate {
  paymentDetails: PaymentDetails // Payment method used for the transaction
  orderStatus: OrderStatus // Transaction status for the order
  currencyUnit: CurrencyUnit
  orderType: OrderType
  transfer?: {
    recipientEmail: string // receipient email address
  }
}

export interface EXTERNAL_FEES {
  cardFeePercentage: number
  cardFeeCents: number
  cryptoFees: number
  extraFees: number
}
export type UserAction<T> = {
  type: string
  payload?: T
}

// Transfer unit Types/enum

export interface CurrencyTransferUnit {
  numberOfUnits: number
  selectedCurrency: BuyCurrencyEnum
  pricePerUnit: number //unit price for 1 currency in AUD
}
export enum UnitTransferStatus {
  Initiated = 'Initiated', // UnitTransfer is initiated
  InProcess = 'InProcess', // UnitTransfer is pending for processing
  Failed = 'Failed', // UnitTransfer has failed (payment transaction fails due to insufficient funds or any other error )
  Completed = 'Completed', // UnitTransfer is successfully completed
}

export interface TransferUnit {
  recipientEmail: string // receipient email address
  transferStatus: UnitTransferStatus // Transaction status for the transfer
  currencyUnit: CurrencyTransferUnit
}

export interface TransferUnitCreated extends TransferUnit {
  transactionId: string
  transferUnitCreatedDate: Date
  recipientName: string
}

// MyTransactions

export interface User_Basic_Info {
  firstName: string
  lastName: string
  email: string
}
export interface Transaction extends OrderCreated {
  user: User_Basic_Info
  lastUpdatedAt: Date
  approvedBy: EnteredBy
}

export enum DIRECTION {
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
}
export enum TransactionType {
  BUY = 'BUY',
  SELL = 'SELL',
  TRANSFER = 'TRANSFER',
}

export interface Passport {
  passportFile?: File | null
  passportNumber: string
  issuingCountry: string
  startDate: Date | string
  expiryDate: Date | string
}

export interface ContactInfo {
  telephoneCountryCode: string
  phoneNumber: number
}

export interface Address {
  houseNumber: string
  streetAddress: string
  additionalAddress: string
  townCity: string
  postcode: string
  country: string
}

export interface BankDetails {
  bankAccountName: string
  bankAccountNumber: string
  iban: string
  swiftBic: string
  bankName: string
  bankAddress: string
  bankCountry: string
}
export interface AdminBankDetails {
  accountName: string
  accountNumber: number
  bankName: string
  branchName: string
  swiftOrBicCode: string
  iban: string
  bankCountry: string
  bankAddress: string
}

export interface KYCInfo {
  optionalNote: string
  kycStatus: KYCStatus
  passport: Passport
  optionalDocument?: File | null
  contactInfo: ContactInfo
  address: Address
  bankDetails: BankDetails
}

export interface User {
  _id: string
  firstName: string
  lastName: string
  email: string
  isVerified: boolean
  accountNumber?: number
  expires?: Date
  createdAt: string
  updatedAt: string
  kycInfo: KYCInfo
  businessInfo: BusinessInfo
  purchaseLimitInCents: number
  isEnabled: boolean
  approvedBy: {
    bySuperAdmin?: {
      firstName: string
      lastName: string
      email: string
    }
    byUserAdmin?: {
      firstName: string
      lastName: string
      email: string
    }
  }
  subscriptions: {
    newsSubscriptionStatus: SubscriptionStatus
  }
  ratings: Ratings
}

export interface Ratings {
  communityAgent: {
    averageRating: number // Average rating as an agent
    totalRatings: number // Total number of ratings received
    totalEarnedLandUnits: number // Payment received by clients as land units
    isRestricted: boolean // Whether the agent is restricted from actions
    reasonToBecomeAgent: string // The reason they want to become an agent
    experienceAndMotivation: string // Details of their experience and motivation for the role
    applicationStatus: CommunityAgentApplicationStatus
    adminResponse: string // Admin's feedback or reason for rejection
    applicationDate: Date // Date when the user applied
    approvedBy: {
      bySuperAdmin?: {
        firstName: string
        lastName: string
        email: string
      }
      byUserAdmin?: {
        firstName: string
        lastName: string
        email: string
      }
    }
  }
  client: {
    averageRating: number // Average rating as a client
    totalRatings: number // Total number of ratings received
    totalSpentLandUnits: number // Total land units spent by the client
    isRestricted: boolean // Whether the client is restricted from actions
  }
}

export interface SuperAdmin {
  firstName: string
  lastName: string
  email: string
  password: string
  isSuperAdmin: boolean
  isVerified: boolean
}

export interface BusinessInfo {
  businessName: string
  businessAddress: string
  registrationNumber: string
  vatNumber: string
  registrationDocument?: File | null
  optionalDocument?: File | null
}

export enum KYCStatus {
  UnVerified = 'UnVerified',
  Saved = 'Details Saved',
  Pending = 'Pending Verification',
  Verified = 'Verified',
  NotApproved = 'Not Approved',
}
export enum CommunityAgentApplicationStatus {
  NotStarted = 'Not Started', // Initial status before application
  Pending = 'Pending', // Application is under review
  Approved = 'Approved', // Application has been approved
  Rejected = 'Rejected', // Application has been rejected
}

export interface UserFormValues extends User {
  newPassword: string | null
  confirmNewPassword: string | null
}

// Stats

export interface LandUnitsStats {
  totalLandUnitPurchase: number
  totalLandUnitPendingPurchase: number
  totalLandUnitPendingSell: number
  totalProjectLandValueInCents: number
}

export interface ProjectDetails {
  projectId: string
  projectName: string
  projectDescription: string
  projectValueInCents: number
  projectCadaster: string
  projectAddress: {
    street: string
    city: string
    postal: string
    state: string
    country: string
  }
  landEnteredBy: EnteredBy
  startDate: Date
  landRemovalDate: Date
}

export interface News {
  id: string
  slug: string
  metaTitle: string
  metaDescription: string
  title: string
  img: File | null
  article: string
  newsEnteredBy: EnteredBy
  newsCategoryId: string
  status: NewsStatus
  sentTo: {
    subscribers: boolean //Sent news link to all the subscribers
    members: boolean //Sent news link to all the registered users
  }
}
export interface NewsCategoryInterface {
  _id?: string
  id?: string
  slug: string
  name: string
  newsEnteredBy: EnteredBy
}

// Define the interface for landEnteredBy
export interface EnteredBy {
  bySuperAdmin?: User_Basic_Info
  byUserAdmin?: User_Basic_Info
}

export interface Statistics {
  approveUserCount: number
  approveUserCommunityAgentCount: number
  totalAudInBank: number
  totaleAUDUnitPurchase: number
  totalEaudUnitPendingSell: number
  totalLandUnitPurchase: number
  totalLandUnitPendingPurchase: number
  totalLandUnitPendingSell: number
  totalProjectLandValueInCents: number
  projectCount: number
  recentTransactionCount: number
}

export interface Question {
  question: string
  options: Option
}
export interface Option {
  A: string
  B: string
  C: string
  D: string
  [key: string]: string // Index signature to allow any string key
}

export interface PayoutInfo {
  userInfo: UserInfo[]
  totalChargeAmountReciviedInBank: number
  totalPaymentAmountInCentsWithoutCardFees: number
  totalPayoutAmount: number
  arrival_date: number
  paidFrom: string
  payoutId: string
  currency: string
}
export interface UserInfo {
  orderAmount: number
  numberOfUnits: number
  currency: string
  selectedCurrency: string
  name: string
  email: string
  orderStatus: string
}

export enum AdminRoles {
  GeneralAdmin = 'GeneralAdmin',
  SuperAdmin = 'SuperAdmin',
  UserAdmin = 'UserAdmin',
  FinanceAdmin = 'FinanceAdmin',
  VotingAdmin = 'VotingAdmin',
  BankAdmin = 'BankAdmin',
  LandAdmin = 'LandAdmin',
  NewsAdmin = 'NewsAdmin',
}

export const ONE_USD_TO_CENTS = 100

export interface SingleQuestionInterface {
  questionTitle: string
  answerKey: string
  answerOptions: {
    A: string
    B: string
    C: string
    D: string
  }
}
export interface VoteTopicObjectInteface {
  voteTopicTitle: string
  voteDescription: string
  voteStatus: string
  startDate: string
  endDate: string
  voteQuestions: SingleQuestionInterface[]
}

export type ProjectColorSatus = 'red' | 'green' | 'yellow'
export interface ProjectObjectInteface {
  _id?: string
  projectName: string
  projectDescription: string
  projectManager: string
  startDate: string
  endDate: string
  projectTimeStatus: ProjectColorSatus
  projectBudgetStatus: ProjectColorSatus
  projectQualityStatus: ProjectColorSatus
  IsProjectComplete: string
}
export interface LandProjectObjectInteface {
  _id?: string
  projectName: string
  type: 'Agricultural' | 'Real Estate'
  location: string
  projectDetails: string
  projectSummary: string
  keyDetailsAndStatistics: string
  landProjectPrimaryImage: string
}
export interface ProjectSuggestionInteface {
  _id?: string
  projectIdea: string
  voteTopicIdea: string
  createdAt?: string
  name?: string
  email?: string
}

export interface RouteType {
  path: string
  element: React.ReactElement
  requiredRoles?: AdminRoles[]
}

export interface ExternalCardCryptoFeeFormData {
  cardFeePercentage: number
  cardFeeCents: number
  cryptoFeePercentage: number
  cryptoFeeCents: number
  extraFees: number
}

export interface ReferenceCode {
  code: string
  isPercent: boolean
  amount: number
  isActive: boolean
  appliesTo: ReferenceCodeAppliesTo
}
export enum ReferenceCodeAppliesTo {
  USER = 'USER',
  BUSINESS = 'BUSINESS',
  BOTH = 'BOTH',
}
export interface SuperAdmin {
  firstName: string
  lastName: string
  email: string
}

export enum NewsStatus {
  Saved = 'Saved',
  Published = 'Published',
}

export enum LandProjectTypes {
  Agricultural = 'Agricultural',
  Real_Estate = 'Real Estate',
}
export interface Subscriber {
  _id: string
  email: string
  subscribedAt: string
}

export enum SubscriptionStatus {
  Subscribed = 'Subscribed',
  Unsubscribed = 'Unsubscribed',
}
export interface UserSubscriptionsInteface {
  newsSubscriptionStatus: SubscriptionStatus
}
