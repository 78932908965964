import React, { useEffect, useState } from 'react'
import Header from '../../component/Sidbar'
import Footer from '../../component/Footer'
import { Link, useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import axios from 'axios'
import moment from 'moment'
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Button,
  ModalFooter,
} from 'reactstrap'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { toast } from 'react-toastify'
import { getGovernanceQuestions } from '../../api'
import { testAxios } from '../../utils/constants'
import { ProjectObjectInteface } from '../../utils/types'
import { ColorBar, TRInfo } from '../../component/misc/smallComponents'
import { getEnteredByName } from '../../utils/utilities'
import { SvgIcons } from '../../component/misc/SvgIcons'
export default function ViewProjects() {
  const [deleteModal, setdeleteModal] = useState(false)
  const [deleteLoading, setdeleteLoading] = useState(false)
  const toogleDeleteModal = () => {
    setdeleteModal(!deleteModal)
  }
  const toogleEditModal = () => {
    setdeleteModal(!deleteModal)
  }
  const [data, setData] = useState<ProjectObjectInteface[]>([])
  const [questionId, setQuestionId] = useState<any>('')
  const [id, setId] = useState<any>()
  const [activeID, setactiveID] = useState<string>('')
  const [showChildComponent, setShowChildComponent] = useState(false)

  const navigate = useNavigate()

  const DeleteProject = async () => {
    try {
      setdeleteLoading(true)
      const url = process.env.REACT_APP_SERVER_URI + `/project/${activeID}`
      const response = await testAxios.delete(url)
      setdeleteLoading(false)
      toogleDeleteModal()
      toast.success(response.data.message, { position: 'bottom-center' })
      getAllData()
    } catch (e) {
      console.log('e:', e)
    }
  }

  const getAllData = async () => {
    const url = process.env.REACT_APP_SERVER_URI + '/project'
    getGovernanceQuestions().then()
    const response: any = await testAxios.get(url)
    setData(response.data)
  }
  useEffect(() => {
    getAllData()
  }, [])

  return (
    <>
      {/* <div>{showChildComponent && <EditQuestions data={id} />}</div> */}
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <Header />

        <div className="content-wrapper card-ui-pages-content-wrapper">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">View Q2 Developments</li>
              <li>
                <QButton
                  onClick={() => navigate('/addProject')}
                  round
                  size="sm"
                  className="ml-3"
                >
                  Add Q2 Development
                </QButton>
              </li>
            </ol>
            <div className="card mb-3">
              <div className="card-header">
                <div className="tr-card-list">
                  {data.map(
                    (project: ProjectObjectInteface,) =>
                      project && ( // Check if transaction is not null
                        <div className="tr-card">

                          <div className="info-text">
                            <div className="info-text-col-wrap">

                              <div className="info-col">
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={project.projectName}
                                    label={"Project Name"}
                                  />
                                </div>
                                <p className='label'>Time Status</p>
                                <div className="mt-1">

                                  <ColorBar color={project.projectTimeStatus} />{' '}
                                </div>

                              </div>
                              <div className="info-col">
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={project.projectManager}
                                    label={"Project Manager"}
                                  />
                                </div>



                                <p className='label'>Budget Status</p>
                                <div className="mt-1">

                                  <ColorBar color={project.projectBudgetStatus} />
                                </div>





                              </div>
                              <div className="info-col">
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={moment(project.startDate).format('DD/MM/YYYY')}
                                    label={"Start Date"}
                                  />
                                </div>

                                <p className='label'>Quality Status</p>
                                <div className="mt-1">

                                  <ColorBar color={project.projectQualityStatus} />{' '}
                                </div>
                              </div>
                              <div className="info-col">
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={moment(project.endDate).format('DD/MM/YYYY')}
                                    label={"End Date"}
                                  />
                                </div>
                                <div className="tr-info-wrap">
                                  <TRInfo
                                    value={project.IsProjectComplete ? "Yes" : "No"}
                                    label={"Completed"}
                                  />
                                </div>


                              </div>
                            </div>



                          </div>

                          <div className="info-icon-wrap">

                            <QButton
                              size="sm"
                              onClick={() => {
                                // navigate(`/editQuestion?id=${project._id}`)
                                navigate('/editProject/' + project._id)
                                // navigate(`/feemanagement`)
                              }}
                              round
                              className="mr-2"
                            >
                              Edit
                            </QButton>
                            <QButton
                              size="sm"
                              onClick={() => {
                                if (project._id) {
                                  setactiveID(project._id)
                                  toogleDeleteModal()
                                }
                              }}
                              round
                            >
                              Delete
                            </QButton>

                          </div>
                        </div>
                      ),
                  )}
                </div>
              </div>
            </div>


            <Modal
              isOpen={deleteModal}
              toggle={toogleDeleteModal}
              className="modal-dialog-centered "
              scrollable
              // size="lg"
              unmountOnClose={true}
            >
              <ModalHeader>Confirm.</ModalHeader>
              <ModalBody>Do you want to delete this Project ?</ModalBody>

              <ModalFooter>
                <QButton round className="mr-3">
                  Close
                </QButton>
                <QButton round loading={deleteLoading} onClick={DeleteProject}>
                  Delete
                </QButton>
              </ModalFooter>
            </Modal>
          </div>
        </div>

        <Footer />
      </body>
    </>
  )
}
