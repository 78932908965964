import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import './index.scss'
import UpdateIcon from '@mui/icons-material/Update'
import { KYCInfo, User } from '../../utils/types'
import { debounce, getDate } from '../../utils/utilities'
import {
  getUserListByNameOrEmailForUpdate,
  putUpdateUserDetails,
} from '../../api'
import { FormGroup } from 'reactstrap'
import SearchInput from '../../component/DesignSystem/Core/Common/SearchInput/SearchInput'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import QButton from '../../component/DesignSystem/Core/Common/QButton/QButton'
import { toast } from 'react-toastify'
import { HasToolTipLabel, SelectComponent, SelectComponentValue, TRInfo } from '../../component/misc/smallComponents'
import { camelCaseToSpaced } from '../../utils/common'

export default function EditUserDetails() {
  const [users, setUsers] = useState<User[]>([])
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [updatedUser, setUpdatedUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [saveMessage, setSaveMessage] = useState('')
  const [searchType, setSearchType] = useState('name') // Default search type is name
  const [searchTerm, setSearchTerm] = useState('')
  const [modal, setModal] = useState(false)
  const [isExportDownloadLoading, setIsExportDownloadLoading] = useState(false)

  const toggle = () => setModal(!modal)

  const updateUserDetails = async () => {
    if (!updatedUser) return
    const isProfileDataChanged =
      JSON.stringify(selectedUser) !== JSON.stringify(updatedUser)
    if (!isProfileDataChanged) return alert('data has not been changed yet')
    setIsLoading(true)
    putUpdateUserDetails(updatedUser)
      .then(async ({ data: { message, user } }) => {
        setSaveMessage(message)
        // handleUserSelect(user)
        setTimeout(() => {
          setSaveMessage('')
          setIsLoading(false)
        }, 500)
        await getUsers()
      })
      .catch((err) => {
        setIsLoading(false)

        console.log('error', err)
      })
  }
  const getUsers = async () => {
    getUserListByNameOrEmailForUpdate(searchType, searchTerm)
      .then((res) => {
        const users = res.data.users
        setUsers(users)
      })
      .catch((err) => {
        console.log('error=============', err)
      })
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setUpdatedUser((prevUser) => ({
      ...prevUser!,
      [name]: value,
    }))
  }

  const handleBusinesInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target
    setUpdatedUser((prevUser) => ({
      ...prevUser!,
      businessInfo: {
        ...prevUser!.businessInfo,
        [name]: value,
      },
    }))
  }

  const handleKYCInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const [, outerField, innerField] = name.split('.')
    setUpdatedUser((prevUser) => ({
      ...prevUser!,
      kycInfo: {
        ...prevUser!.kycInfo,
        [outerField]: {
          ...(prevUser!.kycInfo[outerField as keyof KYCInfo] as any), // Type assertion
          [innerField]: value,
        },
      },
    }))
  }
  const handleUserSelect = (user: User) => {
    setSelectedUser(user)
    setUpdatedUser(user)
  }
  const handleExportUsers = async () => {
    setIsExportDownloadLoading(true)
    try {
      const response = await getUserListByNameOrEmailForUpdate(
        searchType,
        searchTerm,
        true,
      )
      const blob = new Blob([response.data], { type: 'text/csv' })

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'users.csv') // Set the file name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) // Clean up

      setIsExportDownloadLoading(false)
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Failed to download CSV')
      setIsExportDownloadLoading(false)
    }
  }
  // Debounced version of getProjectByLandName for 1.2 seconds
  const debouncedGetPendingApprovalUsers = debounce(getUsers, 500) //500 mili seconds delay here

  useEffect(() => {
    debouncedGetPendingApprovalUsers()
  }, [searchType])

  useEffect(() => {
    if (updatedUser) {
      setModal(true)
    }
  }, [updatedUser])

  return (
    <>
      <body className="fixed-nav sticky-footer dash-img-bg" id="page-top">
        <div className="content-wrapper card-ui-pages-content-wrapper">
          <div className="container-fluid">
            <ol
              className="breadcrumb"
              style={{ paddingTop: '2rem', paddingBottom: '0.6rem' }}
            >
              <li className="breadcrumb-item">
                <Link to="/">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">View/Edit User</li>
            </ol>

            <div className="card mb-3">
              {/* <div className="card-header">
                                <i className="fa fa-list-ul mr-2"></i> List of All Transactions
                            </div> */}

              <div className="card-header">
                <div className="tr-filter-wrapper">


                  <div className="tr-filter-row">



                    <div className="tr-col two">
                      <div className="row">
                        <div className="col">
                          <SelectComponentValue
                            placeholder="Search By"
                            value={searchType}
                            onChange={(value) => setSearchType(value as string)}
                            options={[
                              { value: 'name', label: 'Search By - Name' },
                              { value: 'email', label: 'Search By - Email' },
                            ]} />
                        </div>
                        <div className="col">

                          <SearchInput
                            onClick={getUsers}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            ButtonText="Search"
                            placeholder={'Search'}
                          // loading={userLoading}
                          />
                        </div>


                      </div>

                    </div>
                    <div className="tr-col one">
                      <QButton
                        loading={isExportDownloadLoading}
                        onClick={handleExportUsers}
                      >
                        Download users
                      </QButton>
                    </div>



                  </div>



                </div>

                <div className="tr-card-list">
                  {users.map((user: User, index: number) =>

                    <div className="tr-card">

                      <div className="info-text">
                        <div className="info-text-col-wrap">

                          <div className="info-col">
                            <div className="tr-info-wrap">
                              <TRInfo
                                value={user.firstName + ' ' + user.lastName}
                                label={"Name"}
                              />
                            </div>



                          </div>
                          <div className="info-col">
                            <div className="tr-info-wrap">
                              <TRInfo
                                value={user?.email}
                                label={"Email"}
                              />
                            </div>









                          </div>
                          <div className="info-col">
                            <div className="tr-info-wrap">
                              <TRInfo
                                value={getDate(user?.createdAt)}
                                label={"Date Joined"}
                              />
                            </div>



                          </div>
                          <div className="info-col">
                            <div className="tr-info-wrap">
                              <TRInfo
                                value={user?.accountNumber}
                                label={"Account Number"}
                              />
                            </div>
                            <div className="tr-info-wrap">
                              <TRInfo
                                value={camelCaseToSpaced(user.kycInfo.kycStatus)}
                                label={"Kyc Status"}
                              />
                            </div>


                          </div>
                        </div>


                        {user.kycInfo.optionalNote && <p className='comments'>Note(Optional) : {user.kycInfo.optionalNote}</p>}
                      </div>

                      <div className="info-icon-wrap">

                        <QButton
                          // size="lg"


                          onClick={() => handleUserSelect(user)}
                          round

                        >
                          Update
                        </QButton>



                      </div>
                    </div>
                  )
                  }
                </div>
            
                <Modal
                    isOpen={modal}
                    toggle={toggle}
                    centered
                    size="lg"
                    scrollable
                    onClosed={() => {
                      setUpdatedUser(null)
                      setSelectedUser(null)
                    }}
                  >
                    <ModalHeader toggle={toggle}>
                      Update User Details
                    </ModalHeader>
                    <ModalBody>
                      {saveMessage && (
                        <div className="save-message">{saveMessage}</div>
                      )}

                      {updatedUser && (
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="firstName"
                                value={updatedUser.firstName}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                name="lastName"
                                className="form-control"
                                value={updatedUser.lastName}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Email</label>
                              <div className="input-group mb-3">
                                <p>{updatedUser.email} </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Account Number</label>
                              <p>{updatedUser.accountNumber} </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Country Code</label>
                              <input
                                type="text"
                                name="kycInfo.contactInfo.telephoneCountryCode"
                                className="form-control"
                                value={
                                  updatedUser.kycInfo.contactInfo
                                    .telephoneCountryCode
                                }
                                onChange={handleKYCInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Contact Number</label>
                              <input
                                type="tel"
                                name="kycInfo.contactInfo.phoneNumber"
                                className="form-control"
                                value={
                                  updatedUser.kycInfo.contactInfo.phoneNumber
                                }
                                onChange={handleKYCInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Optional Document</label>
                              <p>
                                <p>Can't upload User document</p>
                              </p>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Purchase Limit in Cents</label>
                              <input
                                type="number"
                                className="form-control"
                                name="purchaseLimitInCents"
                                onChange={handleInputChange}
                                value={updatedUser.purchaseLimitInCents}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>House Number</label>
                              <input
                                type="number"
                                className="form-control"
                                name="kycInfo.address.houseNumber"
                                onChange={handleKYCInputChange}
                                value={updatedUser.kycInfo.address.houseNumber}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Street Address</label>
                              <input
                                type="text"
                                className="form-control"
                                name="kycInfo.address.streetAddress"
                                value={
                                  updatedUser.kycInfo.address.streetAddress
                                }
                                onChange={handleKYCInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Additional Address</label>
                              <input
                                type="text"
                                className="form-control"
                                name="kycInfo.address.additionalAddress"
                                value={
                                  updatedUser.kycInfo.address.additionalAddress
                                }
                                onChange={handleKYCInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Town/City</label>
                              <input
                                type="text"
                                className="form-control"
                                name="kycInfo.address.townCity"
                                value={updatedUser.kycInfo.address.townCity}
                                onChange={handleKYCInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Country</label>
                              <input
                                type="text"
                                className="form-control"
                                name="kycInfo.address.country"
                                value={updatedUser.kycInfo.address.country}
                                onChange={handleKYCInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Postal Code</label>
                              <input
                                type="number"
                                className="form-control"
                                name="kycInfo.address.postcode"
                                value={updatedUser.kycInfo.address.postcode}
                                onChange={handleKYCInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Passport Number</label>
                              <input
                                type="number"
                                className="form-control"
                                name="kycInfo.passport.passportNumber"
                                value={
                                  updatedUser.kycInfo.passport.passportNumber
                                }
                                onChange={handleKYCInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Issuing Country</label>
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  updatedUser.kycInfo.passport.issuingCountry
                                }
                                name="kycInfo.passport.issuingCountry"
                                onChange={handleKYCInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Passport Start Date</label>
                              <input
                                className="form-control"
                                id="datetimeInput"
                                type="date"
                                name="kycInfo.passport.startDate"
                                onChange={handleKYCInputChange}
                                value={
                                  new Date(
                                    updatedUser.kycInfo.passport?.startDate,
                                  )
                                    .toISOString()
                                    .split('T')[0]
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Passport Expiry Date</label>
                              <input
                                className="form-control"
                                type="date"
                                id="datetimeInput"
                                name="kycInfo.passport.expiryDate"
                                onChange={handleKYCInputChange}
                                value={
                                  new Date(
                                    updatedUser.kycInfo.passport?.expiryDate,
                                  )
                                    .toISOString()
                                    .split('T')[0]
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Passport Document</label>

                              <p>No passport document allowed to upload</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Bank Account Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  updatedUser.kycInfo.bankDetails
                                    .bankAccountName
                                }
                                name="kycInfo.bankDetails.bankAccountName"
                                onChange={handleKYCInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Bank Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={updatedUser.kycInfo.bankDetails.bankName}
                                name="kycInfo.bankDetails.bankName"
                                onChange={handleKYCInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>IBAN</label>
                              <input
                                type="text"
                                className="form-control"
                                value={updatedUser.kycInfo.bankDetails.iban}
                                name="kycInfo.bankDetails.iban"
                                onChange={handleKYCInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Bank Account Number</label>
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  updatedUser.kycInfo.bankDetails
                                    .bankAccountNumber
                                }
                                name="kycInfo.bankDetails.bankAccountNumber"
                                onChange={handleKYCInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Bank Address</label>
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  updatedUser.kycInfo.bankDetails.bankAddress
                                }
                                name="kycInfo.bankDetails.bankAddress"
                                onChange={handleKYCInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Bank Country</label>
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  updatedUser.kycInfo.bankDetails.bankCountry
                                }
                                name="kycInfo.bankDetails.bankCountry"
                                onChange={handleKYCInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>SWIFT/BIC</label>
                              <input
                                type="text"
                                className="form-control"
                                value={updatedUser.kycInfo.bankDetails.swiftBic}
                                name="kycInfo.bankDetails.swiftBic"
                                onChange={handleKYCInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <h4>Business Profile</h4>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Business Name</label>
                              <input
                                type="text"
                                className="form-control"
                                value={updatedUser.businessInfo.businessName}
                                name="businessName"
                                onChange={handleBusinesInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Registration Number</label>
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  updatedUser.businessInfo.registrationNumber
                                }
                                name="registrationNumber"
                                onChange={handleBusinesInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Vat Number</label>

                              <input
                                type="text"
                                className="form-control"
                                value={updatedUser.businessInfo.vatNumber}
                                name="vatNumber"
                                onChange={handleBusinesInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Business Address</label>
                              <input
                                type="text"
                                className="form-control"
                                value={updatedUser.businessInfo.businessAddress}
                                name="businessAddress"
                                onChange={handleBusinesInputChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Registration Document</label>

                              <p>No Registration document allowed to upload</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Optional Document</label>
                              <p>
                                No Business Optional document allowed to upload
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </ModalBody>
                    <ModalFooter>
                      <QButton
                        color="primary"
                        onClick={() => {
                          toggle()
                          setTimeout(() => {
                            setUpdatedUser(null)
                            setSelectedUser(null)
                          }, 300)
                        }}
                      >
                        Cancel
                      </QButton>
                      <QButton
                        color="primary"
                        loading={isLoading}
                        onClick={() => {
                          updateUserDetails()
                          toggle()
                        }}
                      >
                        Update
                      </QButton>{' '}
                    </ModalFooter>
                  </Modal>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}
